import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import NavBar from '../nav_bar/index'

import * as privacyPolicyStyles from './privacyPolicy.module.scss'


const PrivacyPolicyComponent = () => {

    const data = useStaticQuery(graphql`
    query {
        markdownRemark {
          frontmatter {
            title
          }
          html
        }
      }
  `)
    return (
      <>
      <NavBar/>
        <div className={`${privacyPolicyStyles.container}`}>
            <h1>{data.markdownRemark.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}></div>
        </div>
      </>

    )
}

export default PrivacyPolicyComponent